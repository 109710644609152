import React, { useState, useEffect } from 'react';
import { Table, Button, Spinner, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import Footer from '../components/Footer';
import axios from '../utils/axios';
import moment from 'moment';
import LOGO from '../assets/logo.png';

const DashboardForSupplier = () => {
  const [loading, setLoading] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  // Get today's date
  const today = moment().format('YYYY-MM-DD');
  // Get the date 7 days from today
  const endDate = moment().add(7, 'days').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(today);
  const [endDateState, setEndDateState] = useState(endDate);
  const [result, setResult] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [selectedStatusName, setSelectedStatusName] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(number);
  };

  const calculateCoffeeBreakPax = (meals) => {
    return meals
      .filter(meal => meal.Package === "COFFEE BREAK" || meal.Package === "COFFEE BREAK II" || meal.Package === "CB I" || meal.Package === "CB II")
      .reduce((total, meal) => total + meal.Pax, 0);
  };



  const sortedResult = selectedStatusName
    ? result.filter(item => item.StatusName === selectedStatusName)
    : result;

  // Sort results by event date in ascending order
  const sortedResultsByDate = [...sortedResult].sort((a, b) => new Date(a.onDate) - new Date(b.onDate));

  useEffect(() => {
    const getBanquetForecast = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/get/banquet/forecast/all/hotel`);
        console.log(response.data);
  
        // Filter meals to include only those with "COFFEE BREAK" package
        const filteredResults = response.data.map(row => ({
          ...row,
          Meals: row.Meals.filter(meal => meal.Package === "COFFEE BREAK")
        }));
        setResult(filteredResults);
  
        // Extract unique StatusName values
        const uniqueStatusNames = [...new Set(filteredResults.map(item => item.StatusName))];
        setStatusNames(uniqueStatusNames);
  
      } catch (err) {
        console.error('Error find banquet forecast:', err);
      } finally {
        setLoading(false);
      }
    };

    getBanquetForecast();
  }, [startDate, endDate]);

  const openModalMenu = async (dateEvent, timeFrom, idHotel) => {
    setShowModal(true);
    console.log(dateEvent);
    try {
      const response = await axios.get(`/api/get/detail/menu/${dateEvent}/${timeFrom}/${idHotel}`);
      console.log("response data: ", response.data);
      setModalContent(response.data); // Set modalContent directly as array of objects
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      setModalContent([]);
    }
  };

  // Filter to include only rows with 'Confirm' status
  const confirmedResults = sortedResultsByDate.filter(row => row.StatusName === 'Confirm');

  return (
    <>
      <div className='container-fluid mt-5'>
        {loading && (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {!loading && confirmedResults.length > 0 && (
          <div className='container'>
            <div className="text-center">
              <img src={LOGO} width={100} alt="Logo" />
            </div>
            <h5 className="text-center">Coffe Break Forecast</h5>
           
            <p className="text-center">
              From: {moment(startDate).format('ll')} to {moment(endDate).format('ll')}
            </p>
            <Table bordered style={{ marginBottom: "10%" }}>
              <thead>
                <tr className="table-dark">
                  <th>Event No</th>
                  <th>Event Date</th>
                  <th>Event Time</th>
                  <th>Hotel</th>
                  <th>Customer</th>
                  <th>Event Type</th>
                  <th>Function Room</th>
                  <th>Setup</th>
                  <th>Set Pax</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {confirmedResults.map((row, index) => {
                  const totalCoffeeBreakPax = calculateCoffeeBreakPax(row.Meals);
                  return (
                    <React.Fragment key={index}>
                      <tr className="table-primary">
                        <td>{row.ID}</td>
                        <td>{moment(row.onDate).format('DD-MMM-YYYY')}</td>
                        <td>{row.TimeSpan}</td>
                        <td>{row.hotel}</td>
                        <td>{row.GuestName}</td>
                        <td>{row.Package}</td>
                        <td>{row.Function}</td>
                        <td>{row.Setup}</td>
                        <td>{row.SetPax}</td>
                        <td className="bg-green">{row.StatusName}</td>
                      </tr>
                      <tr>
                        <td colSpan="10">
                          <Table borderless size='sm'>
                            <thead>
                              <tr>
                                <th>Package</th>
                                <th>Time From</th>
                                <th>Time To</th>
                                <th>Pax</th>
                                <th colSpan={2}>Menu</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.Meals.map((meal, mealIndex) => (
                                <tr key={mealIndex} >
                                  <td>{meal.Package}</td>
                                  <td>{meal.TimeFrom}</td>
                                  <td>{meal.TimeTo}</td>
                                  <td>{meal.Pax}</td>
                                  <td colSpan={2}>
                                    <i className="bi bi-info-circle" onClick={() => openModalMenu(row.onDate, meal.TimeFrom, row.id_hotel)}></i>
                                  </td>
                                </tr>
                              ))}
                              <tr style={{ fontSize: "14px", fontWeight: "bolder",borderTop:"1px solid black" }}>
                                <td colSpan={5}>Total Pax</td>
                                <td>{totalCoffeeBreakPax} Pax</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
            <br />
          </div>
        )}

        {!loading && confirmedResults.length === 0 && (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <p className="text-center mt-3">Load Data from server ....</p>
          </div>
        )}
      </div>
      <Footer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"13px"}}>Menu Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hovered>
            <thead>
              <tr>
                <th>Item</th>
                <th>Group</th>
                <th>Schedule</th>
                <th>Unit</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {modalContent.length > 0 ? (
                modalContent.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>{item.group_name}</td>
                    <td>{item.code}</td>
                    <td>{item.unit}</td>
                    <td>{formatToRupiah(item.price)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardForSupplier;
