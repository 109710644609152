import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import Header from '../components/Navbar';
import Footer from '../components/Footer';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

const MenuList = () => {
  const [loading, setLoading] = useState(false);
  const [listMenu, setListMenu] = useState([]);
  const [listMenuGroup, setListMenuGroup] = useState([]);
  const [listSupplier, setListSupplier] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const openModalAddMenu = () => {
    setEditData(null);
    setShowModal(true);
  };

  const schedules = [
    {
      schedule: '1',
      code: 'CB1',
      timeStart: '< 12:00'
    },
    {
      schedule: '2',
      code: 'CB2',
      timeStart: '> 12:00'
    }
  ];

  const doOpenModalDetail = (item) => {
    setEditData(item);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSave = async (data) => {
    setLoading(true);
    try {
      if (data.id_menu) {
        const response = await axios.put(`/api/menu/update/${data.id_menu}`, data);
        Swal.fire('Success', 'Menu item updated successfully', 'success');
      } else {
        const response = await axios.post(`/api/menu/insert`, data);
        Swal.fire('Success', 'Menu item inserted successfully', 'success');
      }
      getListMenu();
    } catch (err) {
      Swal.fire('Error', 'Error saving menu item', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getListMenu = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/get/menu/list`);
      setListMenu(response.data);
    } catch (err) {
      console.error('Error fetching menu list:', err);
    } finally {
      setLoading(false);
    }
  };

  const getListSupplier = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/get/supplier/list`);
      setListSupplier(response.data);
    } catch (err) {
      console.error('Error fetching supplier list:', err);
    } finally {
      setLoading(false);
    }
  };

  const getListMenuGroup = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/get/groupmenu/list`);
      setListMenuGroup(response.data);
    } catch (err) {
      console.error('Error fetching menu groups:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListMenu();
    getListSupplier();
    getListMenuGroup();
  }, []);

  const renderTableRows = () => {
    const groupedData = listMenu.reduce((acc, item) => {
      if (!acc[item.group_name]) {
        acc[item.group_name] = [];
      }
      acc[item.group_name].push(item);
      return acc;
    }, {});

    return Object.keys(groupedData).map(group => (
      <React.Fragment key={group}>
        <tr>
          <th colSpan="5" className="bg-primary text-center">{group}</th>
        </tr>
        {groupedData[group].map(item => (
          <tr key={item.id_menu} className={item.code === 'CB1' ? 'table-primary' : item.code === 'CB2' ? 'table-danger' : ''}>
            <td>{item.item_name}</td>
            <td>{item.name_supplier}</td>
            <td>{item.code}</td>
            <td>{item.price ? item.price : 'N/A'}</td>
            <td>
              <i onClick={() => doOpenModalDetail(item)} className="bi bi-info-circle-fill"></i>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Header />
      <div className='container-fluid mt-5'>
        {loading ? (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className='container m-5'>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Vendor</th>
                  <th>Schedule</th>
                  <th>Price</th>
                  <th>
                    <i onClick={() => openModalAddMenu()} className="bi bi-clipboard-plus"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows()}
              </tbody>
            </Table>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
        )}
      </div>
      <MenuForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        editData={editData}
        menuGroups={listMenuGroup}
        suppliers={listSupplier}
        schedules={schedules}
      />
      <Footer />
    </>
  );
};

const MenuForm = ({ show, handleClose, handleSave, editData, menuGroups, suppliers, schedules }) => {
  const [formData, setFormData] = useState({
    item_name: '',
    code: '',
    price: '',
    group_id: '',
    supplier_id: ''
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        ...editData,
        group_id: editData.group_id || '',
        supplier_id: editData.supplier_id || ''
      });
    } else {
      setFormData({
        item_name: '',
        code: '',
        price: '',
        group_id: '',
        supplier_id: ''
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    handleSave(formData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{editData ? 'Edit Menu' : 'Add Menu'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-2' controlId="formGroup">
            <Form.Label>Group</Form.Label>
            <Form.Select
              name="group_id"
              value={formData.group_id}
              onChange={handleChange}
            >
              <option value="">Select Group</option>
              {menuGroups.map(group => (
                <option key={group.group_id} value={group.id_menugroup}>
                  {group.group_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-2' controlId="formItemName">
            <Form.Label>Item Name</Form.Label>
            <Form.Control
              type="text"
              name="item_name"
              value={formData.item_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-2' controlId="formSupplier">
            <Form.Label>Supplier</Form.Label>
            <Form.Select
              name="supplier_id"
              value={formData.supplier_id}
              onChange={handleChange}
            >
              <option value="">Select Supplier</option>
              {suppliers.map(supplier => (
                <option key={supplier.id_supplier} value={supplier.id_supplier}>
                  {supplier.name_supplier}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-2' controlId="formCode">
            <Form.Label>Code / Schedule</Form.Label>
            <Form.Select
              name="code"
              value={formData.code}
              onChange={handleChange}
            >
              <option value="">Select Schedule</option>
              {schedules.map(schedule => (
                <option key={schedule.schedule} value={schedule.schedule}>
                  {schedule.code} |  {schedule.timeStart}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuList;
