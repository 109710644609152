import React, { useState, useEffect } from 'react';
import { Container, ButtonGroup, Button, Modal, Form,Row,Col,Table, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import Header from '../components/Navbar';
import Footer from '../components/Footer';
import axios from '../utils/axios';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Select from 'react-select';

import './CalendarMeals.css'; // Import the custom CSS file

const MealsCalendar = () => {
  const { idhotel } = useParams();
  const [loading, setLoading] = useState(true);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [dataMenu, setDataMenu] = useState({ morning: [], afternoon: [] });
  const [menugroup, setMenugroup] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false); // New state for loading indicator
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message

  const handleShowNewModal = () => {
    setShowNewModal(true);
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
  };

  const handleShowModal = async (event) => {
    setSelectedEvent(event);
    
    console.log(event.extendedProps.id_cal)
    try {
      var tgl = moment(event.start).format('Y-MM-DD');
      var menu = event.extendedProps.id_cal;
      const response = await axios.get(`/api/detail/menu/${tgl}/${menu}`);
      console.log(response.data)
      setDataMenu(response.data);
    } catch (error) {
      console.error('Error inserting event:', error);
    } finally {
      fetchEvents(); 
    }


    setShowModalDetail(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    window.location.reload()
    setShowModalDetail(false);
  };

  const handleMultiSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleFormSubmit = async () => {
    const formData = {
      hotel_id: idhotel,
      start_date: startDate,
      end_date: endDate,
     
      menu_groups: selectedOptions.map(option => option.value)
    };

    setSubmitting(true); // Set submitting to true

    try {
      const response = await axios.post('/api/insert/menu/banquet/event', formData);
      if (response.status === 200) {
        setSuccessMessage(response.data.message); // Set the success message
        fetchEvents(); // Refresh the events
        handleCloseNewModal();
      } else {
        console.error('Error inserting event:', response.data);
      }
    } catch (error) {
      console.error('Error inserting event:', error);
    } finally {
      setSubmitting(false); // Set submitting to false
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`/api/get/menu/banquet/calendar/${idhotel}`);
      const transformedEvents = response.data.map(event => ({
        title: event.group_name,
        start: event.date_cal,
        extendedProps: {
          id_cal: event.id_cal,
          hotel_id: event.hotel_id,
          menugroup_id: event.menugroup_id,
          created_at: event.created_at,
          updated_at: event.updated_at
        }
      }));
      setEvents(transformedEvents);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const getMenuGroup = async () => {
    try {
      const response = await axios.get(`/api/get/groupmenu/list`);
      const transformedMenuGroup = response.data.map(group => ({
        value: group.id_menugroup, // assuming id is the unique identifier
        label: group.group_name // assuming name is the display name
      }));
      setMenugroup(transformedMenuGroup);
      
    } catch (error) {
      console.error('Error fetching menu group:', error);
      setLoading(false);
    }
  };

  useEffect(() => {

    fetchEvents();
    getMenuGroup();
  }, [idhotel]);

  return (
    <>
      <Header />
      <div className='container ml-5 mr-5 mt-3'>
        <div className='card p-5'>
          <div className='card-body'>

            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
                {successMessage}
              </Alert>
            )}


            <div className='d-flex justify-content-between align-items-center mb-3'>
              {idhotel === "1" && <h5 className="hotel-title">Ashley Wahid Hasyim</h5>}
              {idhotel === "2" && <h5 className="hotel-title">Ashley Tanah Abang</h5>}
              <div className='d-flex'>
                <ButtonGroup size="sm" aria-label="Basic example">
                  <Button size="sm" variant="secondary" onClick={handleShowNewModal}><i className="bi bi-calendar2-plus-fill"></i> Menu</Button>
                  <Button size="sm" variant="secondary"><i className="bi bi-list-columns"></i> List</Button>
                  <Button size="sm" variant="secondary" onClick={fetchEvents}><i className="bi bi-arrow-clockwise"></i></Button>
                </ButtonGroup>
              </div>
            </div>
            {loading ? (
              <div>Load Calendar...</div>
            ) : (
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                events={events}
                headerToolbar={{
                  end: 'prev,next' // will normally be on the right. if RTL, will be on the left
                }}
                contentHeight="auto"
                eventClick={(info) => {
                  handleShowModal(info.event);
                }}
              />
            )}
            <br/>
            <br/>
          </div>
        </div>
      </div>
      <Footer />

      {/* Detail Menu */}
      <Modal show={showModalDetail} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent ? selectedEvent.title : "Event Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <Container>
      <Row>
        <Col>
          <h6>Morning</h6>
          <Table striped bordered hover style={{fontSize:"11px"}}>
            <thead>
              <tr>
                <th>Item </th>
                <th>Supplier </th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {dataMenu.morning.map((item, index) => (
                <tr key={index}>
                  <td>{item.item_name}</td>
                  <td>{item.name_supplier}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h6>Afternoon</h6>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Item </th>
                <th>Supplier </th>
                <th>Price </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(dataMenu.afternoon).map(key => (
                <tr key={key}>
                  <td>{dataMenu.afternoon[key].item_name}</td>
                  <td>{dataMenu.afternoon[key].name_supplier}</td>
                  <td>{dataMenu.afternoon[key].price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* New Menu Modal */}
      <Modal show={showNewModal} onHide={handleCloseNewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formStartDate" className='mb-2'>
              <Form.Label size='sm'>Start Date</Form.Label>
              <Form.Control
                type="date"
                size='sm'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEndDate" className='mb-2'>
              <Form.Label size='sm'>End Date</Form.Label>
              <Form.Control
                type="date"
                size='sm'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
              />
            </Form.Group>
            <Form.Group controlId="formMultiSelect">
              <Form.Label>Select Menu</Form.Label>
              <Select
                isMulti
                value={selectedOptions}
                onChange={handleMultiSelectChange}
                options={menugroup} // Use the transformed menugroup array here
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Form>
          {submitting && (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit} disabled={submitting}>
            {submitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MealsCalendar;
