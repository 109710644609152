import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import Header from '../components/Navbar';
import Footer from '../components/Footer';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

const SupplierList = () => {
  const [loading, setLoading] = useState(false);
  const [listSupplier, setListSupplier] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [supplierForm, setSupplierForm] = useState({
    id_supplier: '',
    name_supplier: '',
    phone_supplier: '',
    email_supplier: '',
    add_supplier: ''
  });

  const getSupplierList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/get/supplier/list`);
      console.log("response data: ", response.data);
      setListSupplier(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching supplier list:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupplierList();
  }, []);

  const openModalAddSupplier = () => {
    setIsEdit(false);
    setSupplierForm({
      id_supplier: '',
      name_supplier: '',
      phone_supplier: '',
      email_supplier: '',
      add_supplier: ''
    });
    setShowModal(true);
  };

  const openModalEditSupplier = (supplier) => {
    setIsEdit(true);
    setSupplierForm(supplier);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupplierForm({
      ...supplierForm,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEdit) {
        await axios.put(`/api/update/supplier/${supplierForm.id_supplier}`, supplierForm);
        
        Swal.fire('Success', 'Supplier updated successfully', 'success');


      } else {
        await axios.post('/api/add/supplier', supplierForm);

        Swal.fire('Success', 'Supplier added successfully', 'success');
      }
      getSupplierList();
      setShowModal(false);
    } catch (err) {
      console.error("Error saving supplier:", err);
      Swal.fire('Error', 'Error saving supplier', 'error');
    }
  };

  return (
    <>
      <Header />
      <div className='container-fluid mt-5'>
        {loading ? (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className='container m-5'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th style={{fontSize:"18px" ,textAlign:"center"}}>
                    <i className="bi bi-person-plus" onClick={openModalAddSupplier}></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listSupplier.map(supplier => (
                  <tr key={supplier.id_supplier}>
                    <td>{supplier.id_supplier}</td>
                    <td>{supplier.name_supplier}</td>
                    <td>{supplier.phone_supplier}</td>
                    <td>{supplier.email_supplier}</td>
                    <td>{supplier.add_supplier}</td>
                    <td style={{fontSize:"18px" ,textAlign:"center"}} onClick={() => openModalEditSupplier(supplier)}>
                      <i  className="bi bi-person-gear"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"14px"}}>{isEdit ? 'Edit Supplier' : 'Add Supplier'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3">
              <Form.Label >Name</Form.Label>
              <Form.Control
                size='sm'
                type="text"
                name="name_supplier"
                value={supplierForm.name_supplier}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                size='sm'
                type="text"
                name="phone_supplier"
                value={supplierForm.phone_supplier}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                size='sm'
                type="email"
                name="email_supplier"
                value={supplierForm.email_supplier}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                size='sm'
                type="text"
                name="add_supplier"
                value={supplierForm.add_supplier}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {isEdit ? 'Update' : 'Add'} Supplier
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default SupplierList;
