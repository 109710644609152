import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import LOGO from "../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const pdfContentRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const [showModalGeneratePO, setShowModalGeneratePO] = useState(false);
  const [listSupplier, setListSupplier] = useState([]);
  const today = moment().format("YYYY-MM-DD");
  const endDate = moment().add(7, "days").format("YYYY-MM-DD");
  const [startDateState, setStartDateState] = useState(today);
  const [endDateState, setEndDateState] = useState(endDate);

  const [result, setResult] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [selectedStatusName, setSelectedStatusName] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [pickHotel, setPickdHotel] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [queryResultMenuPO, setQueryResultMenuPO] = useState([]);

  const handleSubmitGeneratePO = async (e) => {
    e.preventDefault();
    console.log('Selected Supplier:', selectedSupplier);
    console.log('Event Date:', eventDate);
    console.log('Hotel Choosen:', pickHotel);
    try {
      const response = await axios.get(`/api/calculate/po/generate/${pickHotel}/${selectedSupplier}/${eventDate}`);
      console.log("response data: ", response.data);
      setQueryResultMenuPO(JSON.stringify(response.data, null, 2));
    } catch (err) {
      console.error("Error find banquet forecast:", err);
    }
  };

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(number);
  };

  const handleClick = (event) => {
    setSelectedHotel(event.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Search clicked with start date:", startDateState, "and end date:", endDateState);
    try {
      const response = await axios.get(`/api/get/banquet/forecast/${selectedHotel}/${startDateState}/${endDateState}`);
      console.log("response data: ", response.data);
      const filteredResults = response.data.map((row) => ({
        ...row,
        Meals: row.Meals.filter(
          (meal) =>
            meal.Package !== "LUNCH" &&
            meal.Package !== "FULLDAY MEETING" &&
            meal.Package !== "HALF DAY" &&
            meal.Package !== "FULL DAY" &&
            meal.Package !== "FULLDAY" &&
            meal.Package !== "FULLBOARD MEETING" &&
            meal.Package !== "DINNER" &&
            meal.Package !== "HALFDAY MEETING" &&
            meal.Package !== "ROOM RENTAL"
        ),
      }));
      setResult(filteredResults);
      const uniqueStatusNames = [...new Set(filteredResults.map((item) => item.StatusName))];
      setStatusNames(uniqueStatusNames);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      setResult([]);
    } finally {
      setLoading(false);
    }
  };

  const openModalMenu = async (dateEvent, timeStart, idHotel) => {
    setShowModal(true);
    console.log(dateEvent);
    try {
      const response = await axios.get(`/api/get/detail/menu/${dateEvent}/${timeStart}/${idHotel}`);
      console.log("response data: ", response.data);
      setModalContent(response.data);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
      setModalContent("Error loading data.");
    }
  };

  const calculateCoffeeBreakPax = (meals) => {
    return meals
      .filter(
        (meal) =>
          meal.Package === "COFFEE BREAK" ||
          meal.Package === "COFFEE BREAK II" ||
          meal.Package === "CB I" ||
          meal.Package === "CB II"
      )
      .reduce((total, meal) => total + parseInt(meal.Pax, 10), 0);
  };

  const handleStatusChange = (event) => {
    setSelectedStatusName(event.target.value);
  };

  const sortedResult = selectedStatusName ? result.filter((item) => item.StatusName === selectedStatusName): result;

  const doPreviewPO = async (hotelID, banquetID, eventDate) => {
    history.push(`/purchase/order/${hotelID}/${banquetID}/${eventDate}`);
  };

  const openModalPO = async () => {
    try {
      const response = await axios.get(`/api/get/supplier/list`);
      console.log("response data: ", response.data);
      setListSupplier(response.data);
      setShowModalGeneratePO(true);
    } catch (err) {
      console.error("Error find banquet forecast:", err);
    }
  };

  const handleGeneratePOBasedOnDate = () => {
    console.log('Generate PO button clicked');
    history.push(`/purchase/order/bydate/${pickHotel}/${selectedSupplier}/${eventDate}`)
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Tentative":
        return "table-primary";
      case "Confirm":
        return "table-success";
      default:
        return "";
    }
  };

  const sortedResultsByDate = [...sortedResult].sort(
    (a, b) => new Date(a.onDate) - new Date(b.onDate)
  );

  useEffect(() => {
    const getDataBanquetfromDB = async () => {
      setLoading(true);
      console.log("Search clicked with start date:", startDateState, "and end date:", endDateState);
      try {
        const response = await axios.get(`/api/get/banquet/forecast/all/hotel`);
        console.log("response data: ", response.data);
        const filteredResults = response.data.map((row) => ({
          ...row,
          Meals: row.Meals.filter(
            (meal) =>
              meal.Package !== "LUNCH" &&
              meal.Package !== "FULLDAY MEETING" &&
              meal.Package !== "HALF DAY" &&
              meal.Package !== "FULL DAY" &&
              meal.Package !== "FULLDAY" &&
              meal.Package !== "FULLBOARD MEETING" &&
              meal.Package !== "DINNER" &&
              meal.Package !== "HALFDAY MEETING" &&
              meal.Package !== "ROOM RENTAL"
          ),
        }));
        setResult(filteredResults);
        const uniqueStatusNames = [...new Set(filteredResults.map((item) => item.StatusName))];
        setStatusNames(uniqueStatusNames);
      } catch (err) {
        console.error("Error find banquet forecast:", err);
      } finally {
        setLoading(false);
      }
    };
    getDataBanquetfromDB();
  }, []);

  const sendPDFToVendor = async (event) => {
    event.preventDefault();
    try {
      console.log("button export is pressed!");
      const htmlContent = `
            <html>
                <head>
                    
                </head>
                <body>${pdfContentRef.current.innerHTML}</body>
            </html>
        `;
      const response = await axios.post(`/api/export/pdf/banquet`, {
        html: htmlContent,
        selectHotel: selectedHotel,
        statDate: startDateState,
        endDate: endDateState,
      });
      if (response.status === 200) {
        console.log("api is fetched!");
        console.log("response: ", response);
        Swal.fire({
          icon: "success",
          title: "Success",
        });
      }
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to export PDF. Please try again later.",
      });
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid mt-5">
        <div className="container pl-5 pr-5">
          <Form onSubmit={handleSearch}>
            <Row>
              <Col md="2" className="mb-2">
                <Form.Check
                  type="radio"
                  name="hotel"
                  value="1"
                  style={{ fontSize: "16px" }}
                  label="Ashley Wahid Hasyim"
                  checked={selectedHotel === "1"}
                  onChange={handleClick}
                />
              </Col>
              <Col md="2" className="mb-2">
                <Form.Check
                  type="radio"
                  name="hotel"
                  value="2"
                  style={{ fontSize: "16px" }}
                  label="Ashley Tanah Abang"
                  checked={selectedHotel === "2"}
                  onChange={handleClick}
                />
              </Col>
              <Col md="2" className="mb-2">
                <Form.Control
                  required
                  size="sm"
                  className="text-center"
                  placeholder="Start"
                  type="date"
                  value={startDateState}
                  onChange={(e) => setStartDateState(e.target.value)}
                />
              </Col>
              <Col md="2" className="mb-2">
                <Form.Control
                  required
                  size="sm"
                  type="date"
                  className="text-center"
                  value={endDateState}
                  onChange={(e) => setEndDateState(e.target.value)}
                />
              </Col>
              {sortedResultsByDate.length > 0 && (
                <Col md="2" className="mb-2">
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    onChange={handleStatusChange}
                  >
                    <option value="">Sort by</option>
                    {statusNames.map((status, index) => (
                      <option key={index} value={status}>
                        {status}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              )}
              <Col md="2" className="d-flex flex-row">
                <Button
                  variant="primary"
                  type="submit"
                  className="btn btn-sm me-2"
                  style={{
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i className="bi bi-search" style={{ marginRight: "5px" }}></i> Filter
                </Button>
                {!loading && sortedResultsByDate.length > 0 && (
                  <Button
                    variant="warning"
                    type="button"
                    className="ms-1 btn btn-sm"
                    style={{
                      fontSize: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={openModalPO}
                  >
                    <i className="bi bi-card-checklist" style={{ marginRight: "5px" }}></i> Generate PO
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        <hr />
        {loading && (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {!loading && sortedResultsByDate.length > 0 && (
          <div ref={pdfContentRef} className="container">
            <div className="text-center">
              <img src={LOGO} width={100} alt="Logo" />
            </div>
            <h5 className="text-center">Coffe Break Order</h5>
            <p className="text-center">
              {selectedHotel === "1" && <span>Ashley Wahid Hasyim</span>}
              {selectedHotel === "2" && <span>Ashley Tanah Abang</span>}
            </p>
            <p className="text-center">
              From: {moment(startDateState).format("ll")} to{" "}
              {moment(endDateState).format("ll")}
            </p>
            <Table bordered style={{ marginBottom: "10%" }}>
              <thead>
                <tr className="table-dark">
                  <th>Event No</th>
                  <th>Hotel</th>
                  <th>Event Date</th>
                  <th>Customer</th>
                  <th>Event Type</th>
                  <th>Time</th>
                  <th>Function Room</th>
                  <th>Setup</th>
                  <th>Set Pax</th>
                  <th>Salesman</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedResultsByDate.map((row, index) => {
                  const totalCoffeeBreakPax = calculateCoffeeBreakPax(row.Meals);
                  return (
                    <React.Fragment key={index}>
                      <tr
                        className={getStatusClass(row.StatusName)}
                        style={{
                          fontWeight: "bold",
                          flexDirection: "row",
                          fontSize: "10px",
                          textAlign: "justify",
                          fontStyle: "italic",
                        }}
                      >
                        <td>{row.ID}</td>
                        <td>{row.hotel}</td>
                        <td>{moment(row.onDate).format("DD-MMM-YYYY")}</td>
                        <td>{row.GuestName}</td>
                        <td>{row.Package}</td>
                        <td>{row.TimeSpan}</td>
                        <td>{row.Function}</td>
                        <td>{row.Setup}</td>
                        <td>{row.SetPax}</td>
                        <td>{row.Salesman}</td>
                        <td>{moment(row.CreatedOn).format("DD-MMM-YYYY HH:mm")}</td>
                        <td>{row.StatusName}</td>
                        <td>
                          <Button
                            style={{ fontSize: "12px" }}
                            className="btn btn-sm"
                            variant="primary"
                            onClick={() => doPreviewPO(row.id_hotel, row.ID, row.onDate)}
                          >
                            <i className="bi bi-file-pdf-fill"></i>
                          </Button>
                        </td>
                      </tr>
                      <tr className="table-light">
                        <td colSpan="13" className="p-3">
                          <Table bordered size="sm">
                            <thead>
                              <tr className="table-light text-center">
                                <th>Package</th>
                                <th>Location</th>
                                <th>Time From</th>
                                <th>Time To</th>
                                <th>Menu Item</th>
                                <th>Pax</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.Meals.map((meal, mealIndex) => (
                                <tr key={mealIndex} className="table-light">
                                  <td>{meal.Package}</td>
                                  <td>{meal.Period}</td>
                                  <td>{meal.TimeFrom}</td>
                                  <td>{meal.TimeTo}</td>
                                  <td className="text-center">
                                    <i
                                      className="bi bi-info-square"
                                      onClick={() => openModalMenu(row.onDate, meal.TimeFrom, row.id_hotel)}
                                    ></i>
                                  </td>
                                  <td>{meal.Pax}</td>
                                </tr>
                              ))}
                              <tr
                                className="table-info"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                              >
                                <td colSpan={5}>Total Pax</td>
                                <td>{totalCoffeeBreakPax} Pax</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
            <br />
          </div>
        )}
        {!loading && result.length === 0 && (
          <p className="text-center mt-3">
            No data found for the selected criteria or please choose another date
          </p>
        )}
      </div>
      <Footer />

      {/* Modal Menu List */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "13px" }}>Menu Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hovered>
            <thead>
              <tr>
                <th>Item</th>
                <th>Group</th>
                <th>Schedule</th>
                <th>Unit</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {modalContent.length > 0 ? (
                modalContent.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>{item.group_name}</td>
                    <td>{item.code}</td>
                    <td>{item.unit}</td>
                    <td>{formatToRupiah(item.price)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Pick Supplier */}
      <Modal show={showModalGeneratePO} onHide={() => setShowModalGeneratePO(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "13px" }}>Choose Supplier & Event Date to Generate PO</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitGeneratePO}>
          <Modal.Body>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Hotel</Form.Label>
              <Form.Select size="sm" required value={pickHotel} onChange={(e) => setPickdHotel(e.target.value)}>
                <option>Select Hotel</option>
                <option value={1}>Ashley Wahid Hasyim</option>
                <option value={2}>Ashley Tanah Abang</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Supplier</Form.Label>
              <Form.Select size="sm" required value={selectedSupplier} onChange={(e) => setSelectedSupplier(e.target.value)}>
                <option>Select Supplier</option>
                {listSupplier.length > 0 ? (
                  listSupplier.map((x, index) => (
                    <option key={index} value={x.id_supplier}>{x.name_supplier}</option>
                  ))
                ) : (
                  <option>No Data Supplier</option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label size="sm">Date Event</Form.Label>
              <Form.Control size="sm" type="date" placeholder="Date Event" required value={eventDate} onChange={(e) => setEventDate(e.target.value)} />
            </Form.Group>
            <pre>{queryResultMenuPO}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm" variant="secondary" onClick={() => setShowModalGeneratePO(false)}>
              Close
            </Button>
            <Button variant="primary" className="btn btn-sm" type="submit">
              Calculate
            </Button>
            {queryResultMenuPO && queryResultMenuPO.length > 0 && (
              <Button size="sm" variant="success" onClick={handleGeneratePOBasedOnDate}>
                Generate PO
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Dashboard;
