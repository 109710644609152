import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";


const PurchaseOrderByDate = () => {
  const { IDhotel, IDSupplier, dateEvent } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const pdfContentRef = useRef(null);

  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(number);
  };

  const getDetailPObyDate = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/detail/po/generate/${IDhotel}/${IDSupplier}/${dateEvent}`);
      setData(response.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDetailPObyDate();
  }, [IDhotel, IDSupplier, dateEvent]);

  const calculateGrandTotal = (menuItems) => {
    return menuItems.reduce((total, item) => total + (item.price * item.totalPax), 0);
  };

  const generatePONumber = (date, hotelCode, uniqueNumber) => {
    return `${moment(date).format("YYYY/MM/DD")}/${hotelCode}/FB/${uniqueNumber}`;
  };

  const poNumber = data ? generatePONumber(dateEvent, data.hotel.code_hotel, "0001") : "";

  const generatePDF = async () => {
    const element = pdfContentRef.current;
    const opt = {
      margin: [0.2, 0.2, 0.2, 0.2], // top, left, bottom, right
      filename: `purchase_order_${poNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.90 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
     html2pdf().from(element).set(opt).save();
    try {
      const pdfBlob = await html2pdf().from(element).set(opt).output('blob');
      
      if (!(pdfBlob instanceof Blob)) {
        throw new Error('Failed to generate a valid Blob.');
      }

      const formData = new FormData();
      formData.append('file', pdfBlob, `purchase_order_${poNumber}.pdf`);
      
      const response = await axios.post('/api/export/pdf/po', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          html: `PDF generated successfully. <a href="${response.data.link_po}" target="_blank">Download here</a>`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error('Error generating or uploading PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to generate or upload PDF. Please try again later.',
      });
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-2 mb-6" style={{ paddingLeft: "8px", paddingRight: "8px" }} ref={pdfContentRef}>
        {loading ? (
          <p>Loading Data Calculation...</p>
        ) : data ? (
          <>
            <Container fluid className="m-3" style={{ border: "1px solid black", borderRadius: "12px" }}>
              <Row className="mb-4 mt-3 text-center">
                <Col>
                  <img src={data.hotel.logo_hotel} style={{ marginBottom: "2%", marginTop: "3%" }} alt="Hotel Logo" />
                  <h6><b>PURCHASE ORDER</b></h6>
                  <p>{data.hotel.addr_hotel}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <td>
                          <Table style={{ fontSize: "11px" }}>
                            <tbody>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>PO No:</strong></td>
                                <td><b>{poNumber}</b></td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>PO Created:</strong></td>
                                <td>{moment().format("LL")}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Expected Arrival:</strong></td>
                                <td>{moment(dateEvent).subtract(1, "days").format("LL")} : 24:00</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Event Date:</strong></td>
                                <td>{moment(dateEvent).format("LL")}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Address:</strong></td>
                                <td>{data.hotel.addr_hotel}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table style={{ fontSize: "11px" }}>
                            <tbody>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>To:</strong></td>
                                <td>{data.supplier.name_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Address:</strong></td>
                                <td>{data.supplier.add_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Attn:</strong></td>
                                <td>{data.supplier.name_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Phone:</strong></td>
                                <td>{data.supplier.phone_supplier}</td>
                              </tr>
                              <tr style={{ borderColor: "transparent" }}>
                                <td><strong>Email:</strong></td>
                                <td>{data.supplier.email_supplier}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="mb-2"><span style={{ fontWeight: "bold" }}>Event</span>: {data.hotel.nm_hotel}</p>
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Item</th>
                        <th>Menu</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.menu.map((menuItem, index) => (
                        <tr key={index}>
                          <td>{menuItem.code}{menuItem.id_menu}{menuItem.group_id}</td>
                          <td>{menuItem.item_name}</td>
                          <td>{menuItem.group_name}</td>
                          <td>{menuItem.totalPax} {menuItem.unit}</td>
                          <td>{formatToRupiah(menuItem.price)}</td>
                          <td>{formatToRupiah(menuItem.price * menuItem.totalPax)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5" className="text-right"><strong>Grand Total</strong></td>
                        <td>{formatToRupiah(calculateGrandTotal(data.menu))}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="mb-2"><span style={{ fontWeight: "bold" }}>Payment</span>: 14 day after received invoice</p>
                  <p style={{ textAlign: "justify" }}>
                    <strong>Anti Bribery / Anti Corruption undertaking</strong>
                    <br />
                    Anti-Bribery / Anti-Corruption Undertaking Supplier Agrees and Undertakes that in connection with Purchase Order, it will comply with all applicable government laws, rules, and regulations of The Anti-Bribery / Anti-Corruption Policy. Supplier has not and will not directly or indirectly offer to or pay any money or anything of value to hotel’s employees or any other person in connection with the delivery of the product or the service performed under this Purchase Order. Commencement of executions of this order deems that the supplier/subcontractor accepts the terms and conditions of this order.
                  </p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="d-flex justify-content-between">
                    <div className="text-left">
                      <p>Prepared</p>
                      <p><b>Purchasing</b></p>
                      <p>___________________</p>
                    </div>
                    <div className="text-right">
                      <p>Approved by</p>
                      <p><b>Purchasing Manager</b></p>
                      <p>___________________</p>
                    </div>
                    <div className="text-right">
                      <p>Approved by</p>
                      <p><b>Director of Finance</b></p>
                      <p>___________________</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <br />
            <br />
            <br />
            <br />
          </>
        ) : (
          <p>No data available.</p>
        )}
      </Container>
      <Container className="d-flex flow-row mt-4 justify-content-end" style={{ paddingBottom: "100px" }}>
        <Button variant="primary" type="button" className="ms-1" style={{ fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={generatePDF}>
          Export PDF
        </Button>
      </Container>
      <Footer />
    </>
  );
};

export default PurchaseOrderByDate;
