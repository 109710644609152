import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import "./Dashboard.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";

const PurchaseOrderView = () => {
  const pdfContentRef = useRef(null);
  const { IDhotel, IDbanquet, dateEvent } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataBanquet, setDataBanquet] = useState();
  const [dataHotel, setDataHotel] = useState();
  const [dataMenu, setDataMenu] = useState([]);
  const [exportPressed, setExportPressed] = useState(false);
  const [listedPO, setListedPO] = useState([]);
  
  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(number);
  };

  const getDetailDataPO = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/detail/data/po/${IDhotel}/${IDbanquet}/${dateEvent}`
      );
      console.log(response.data);
      setDataBanquet(response.data.banquet);
      setDataHotel(response.data.hotel);
      setDataMenu(response.data.menu);
    } catch (err) {
      console.error("Error finding banquet forecast:", err);
    } finally {
      setLoading(false);
    }
  };

  const getGeneratedPO = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/list/file/po/${IDhotel}/${IDbanquet}/${dateEvent}`);
      console.log(response.data);
      setListedPO(response.data)
    } catch (err) {
      console.error("Error finding banquet forecast:", err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
   
    getDetailDataPO();
    getGeneratedPO();
  }, [IDhotel, IDbanquet, dateEvent]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (!dataBanquet) {
    return <div>No data found</div>;
  }

  // Group dataMenu by code
  const groupedMenu = dataMenu.reduce((acc, menuItem) => {
    (acc[menuItem.code] = acc[menuItem.code] || []).push(menuItem);
    return acc;
  }, {});

  // Function to get time range string
  const getTimeRange = (code) => {
    const timeRange = dataMenu.find((menuItem) => menuItem.code === code);
    return timeRange ? `${timeRange.TimeFrom} - ${timeRange.TimeTo}` : "";
  };

  // Calculate grand total
  const calculateGrandTotal = () => {
    return dataMenu.reduce((total, menuItem) => {
      return total + (menuItem.price * dataBanquet.SetPax);
    }, 0);
  };

  const generatePDF = async () => {
    const element = pdfContentRef.current;
    const opt = {
      margin: [0.2, 0.2, 0.2, 0.2], // top, left, bottom, right
      filename: `purchase_order_${dataBanquet.ID}.pdf`,
      image: { type: 'jpeg', quality: 0.90 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    // html2pdf().from(element).set(opt).save();


    try {
      // Generate PDF and convert it to Blob
      const pdfBlob = await html2pdf().from(element).set(opt).output('blob');
      // console.log('Generated PDF Blob:', pdfBlob);

      // Ensure the Blob is valid
      if (!(pdfBlob instanceof Blob)) {
        throw new Error('Failed to generate a valid Blob.');
      }

      // Create form data and append the Blob and other data
      const formData = new FormData();
      formData.append('file', pdfBlob, 'purchase_order.pdf');
      formData.append('banquetID', dataBanquet.ID);
      
      // console.log(formData);
      // Upload the PDF to the server
      const response = await axios.post('/api/export/pdf/po', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log(response.data);
      const link_po = response.data.link_po;
      const mess =  response.data.message;
      if (response.status === 200 || response.status === 201) {
        getGeneratedPO()
        Swal.fire({
          icon: 'success',
          title: 'Success',
          html: `${mess}, <a href="${link_po}" target="_blank">Download here</a>`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${mess}`,
        });
      }
    } catch (error) {
      console.error('Error generating or uploading PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to generate or upload PDF. Please try again later.',
      });
    }
};

  return (
    <>
      <Header />
      <div
        className="m-2"
        style={{
          padding: "2px", // Increased padding for better appearance
          border: "1px solid #898989",
          borderRadius: "12px",
          fontFamily: "'Arial', sans-serif", // Improved font quality
        }}
        ref={pdfContentRef}
      >

        {/* PDF content starts here */}
        <Container
          className="mt-2 mb-2"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <Row className="mb-4 mt-3 text-center">
            <Col>
              {IDhotel === "1" && <h5>Ashley Wahid Hasyim</h5>}
              {IDhotel === "2" && <h5>Ashley Tanah Abang</h5>}
              <h6>PURCHASE ORDER</h6>
              <p> {dataHotel.addr_hotel}</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <div>
                <Table size="sm">
                  <tbody>
                    <tr>
                      <td>
                        <Table style={{fontSize:"11px"}}>
                          <tbody>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>PO No:</strong>
                              </td>
                              <td>{dataBanquet.ID}</td>
                            </tr>

                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>PO Created:</strong>
                              </td>
                              <td>{moment().format("LL")}</td>
                            </tr>

                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Expected Arrival:</strong>
                              </td>
                              <td>
                                {moment(dataBanquet.onDate)
                                  .add(-1, "days")
                                  .format("LL")}{" "}
                                : 24:00{" "}
                              </td>
                            </tr>

                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Event Date:</strong>
                              </td>
                              <td> {moment(dataBanquet.onDate).format("LL")}</td>
                            </tr>

                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Address:</strong>
                              </td>
                              <td>
                                {dataHotel.addr_hotel}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table style={{fontSize:"11px"}}>
                          <tbody>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>To:</strong>
                              </td>
                              <td>RR Cake</td>
                            </tr>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Address:</strong>
                              </td>
                              <td>JL.Jembatan II No.1p Jakarta Utara</td>
                            </tr>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Attn:</strong>
                              </td>
                              <td>Pak Rudi</td>
                            </tr>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Phone:</strong>
                              </td>
                              <td>081510057869</td>
                            </tr>
                            <tr style={{ borderColor: "transparent" }}>
                              <td>
                                <strong>Email:</strong>
                              </td>
                              <td>ragamrasa@gmail.com</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
           
            <Col>
              <p className="mb-2"><span style={{fontWeight:"bold"}}>Event</span> : {dataBanquet.GuestName} </p>
              <Table size="sm" bordered>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Item</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedMenu).map((code, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td colSpan="6" className="text-center font-weight-bold">
                          {code.toUpperCase()} {getTimeRange(code)}
                        </td>
                      </tr>
                      {groupedMenu[code].map((menuItem, index) => (
                        <tr key={index}>
                          <td>AHG-000-00{menuItem.id_menu}</td>
                          <td>{menuItem.item_name}</td>
                          <td>{menuItem.unit}</td>
                          <td>{dataBanquet.SetPax}</td>
                          <td>{formatToRupiah(menuItem.price)}</td>
                          <td>{formatToRupiah(menuItem.price * dataBanquet.SetPax)}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td colSpan="5" className="text-right">
                      <strong>Grand Total</strong>
                    </td>
                    <td>{formatToRupiah(calculateGrandTotal())}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={8}>
              <p style={{textAlign:"justify"}}>
                <strong>Anti Bribery / Anti Corruption undertaking</strong>
                <br />
                Anti-Bribery / Anti-Corruption Undertaking Supplier Agrees and
                Undertakes that in connection with Purchase Order, it will all
                applicable government laws, rules, and regulation of The
                Anti-Bribery / Anti-Corruption Policy. Supplier has not and will
                not directly or indirectly offer to or pay any money or anything
                value to hotel’s employees or any other person in connection
                with the delivery product or the service performed under this
                Purchase Order. Commencement of executions of this order deems
                that the supplier/subcontractor accept the terms and conditions
                of this order.
              </p>
              <div className="text-right">
                <p>Director of Finance</p>
                <p>___________________</p>
              </div>
            </Col>
          </Row>

        </Container>

        {/* PDF content ends here */}
      </div>
      <Container
        className="d-flex flow-row mt-4 justify-content-end"
        style={{ paddingBottom: "100px" }}
      >
        <Button
          variant="primary"
          type="submit"
          className="ms-1 "
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={generatePDF}
        >
          Export PDF
        </Button>
      </Container>

      <Container className="d-flex flow-row mt-1 mb-5 justify-content-start" style={{ paddingBottom: "100px" }}>
        <h6>Listed Generated PO</h6>
        <br/>
        <ol>
        {listedPO.length > 0 ? (
                listedPO.map((file, index) => (
                  <li key={index}>
                    <a href={file.link_po} target="_blank" download>{file.created_at}</a>
                  </li>
                ))
              ) : (
                <li>
                  <p className="text-center">No data available</p>
                </li>
              )}
        </ol>
      </Container>
      <Footer />
    </>
  );
};

export default PurchaseOrderView;
