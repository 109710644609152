import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Login from "./pages/Login";
import MealsCalendar from "./pages/MealCalendar";
import PurchaseOrderView from './pages/PurchaseOrderView'
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import DashboardForSupplier from "./pages/DashboardForSupplier";
import MenuList from "./pages/MenuList";
import SupplierList from "./pages/SupplierList";
import PurchaseOrderByDate from "./pages/PurchaseOrderByDate"
function App() {
  return (  
    <Router> 
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/menu/list" component={MenuList} />
        <PrivateRoute exact path="/supplier/list" component={SupplierList} />
        <PrivateRoute exact path="/calendar/:idhotel" component={MealsCalendar} />
        <PrivateRoute exact path="/dashboard/supplier" component={DashboardForSupplier} />
        <PrivateRoute exact path="/purchase/order/:IDhotel/:IDbanquet/:dateEvent" component={PurchaseOrderView} />
        <PrivateRoute exact path="/purchase/order/bydate/:IDhotel/:IDSupplier/:dateEvent" component={PurchaseOrderByDate} />
        
      </Switch>
    </Router>
  );
}

export default App;


